import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import firebase from "gatsby-plugin-firebase"


const AboutPage = ({ data }) => {

  React.useEffect(() => {
    firebase
      .analytics()
      .logEvent("visited_about_Page")
      
  }, [])

  
  return <Layout>
    <SiteMetadata title="About - LostFalcon | LostFalcon" description="Have an indepth knowledge of your unexplored places, by just swiping the cards." />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About LostFalcon
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              Before Visiting any place, have a look at the LostFalcon
            </h2>
            <div className="mt-4 leading-loose">
              Many times, we lost in the new place. We dont know about the place very well. Unknowingly, we make mistake in these tourist destination.
              <br />
              <br />
              Lost Falcon is started with this regard to avoid such mistakes and know about the place beforehand. This will enhance your travelling experiences.
              <br />
              <br />
              We're happy to hear from you:
              <br />
              <a
                href="mailto:contact@lostfalcon.com"
                className="border-b border-gray-500 hover:border-blue-600 hover:text-blue-600"
              >
                contact@lostfalcon.com
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-2/5 md:pl-12">
            <Img
              fluid={data.author.childImageSharp.fluid}
              alt="LostFalcon"
              className="rounded-md shadow-md"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
}

export default AboutPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "images/author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
